import { updateRepel } from "../utils/Tools";

export default class Affector {
    defaultRepel: number = window.repelForce || 2000;
    constructor(private host: HTMLElement, private force: number = 0) {
        this.bindEvents();
    }

    private bindEvents(): void {
        this.host.addEventListener('mouseenter', this.mouseenter);
        this.host.addEventListener('mouseleave', this.mouseleave);
    }

    public destroy(): void {
        this.host.removeEventListener('mouseenter', this.mouseenter);
        this.host.removeEventListener('mouseleave', this.mouseleave);
    }

    private mouseenter = (): void => updateRepel(this.force);
    private mouseleave = (): void => updateRepel(this.defaultRepel);
}