import React, { Component } from 'react'
import RepelAffector from '../partials/RepelAffector'
import { Burger } from '../styles/components/Header'

export default class Header extends Component {
  render() {
    return (
      <div className="header">
        <RepelAffector force={7000}>
          <Burger>
            <svg className="burger__svg " width="34" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="burger__path burger__path--1" d="M17 1c0 10 0 10-10 10S1-5 10 4l14 14" stroke="#eee" stroke-width="2" />
              <path className="burger__path burger__path--1" d="M17 21c0-10 0-10 10-10s6-16-3-7L10 18" stroke="#eee" stroke-width="2" />
            </svg>
          </Burger>
        </RepelAffector>
      </div>
    )
  }
}
