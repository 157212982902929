import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Affector from '../components/Affector';


export default class RepelAffector extends Component {
    static propTypes = {
        force: PropTypes.number
    }
    affector: HTMLElement;
    affectorInstance: Affector = null

    constructor({props}) {
        super(props);
    }

    componentDidMount() {
        if (this.affector) {
            this.affectorInstance = new Affector(this.affector, this.props.force);
        }
    }

    componentWillUnmount() {
        if (this.affectorInstance) {
            this.affectorInstance.destroy();
            this.affectorInstance = null;
        }
    }

    render() {
        const childElement = React.Children.only(this.props.children);

        return React.cloneElement(childElement, {ref: (el: HTMLElement) => this.affector = el});
    }
}
