import { gsap, Power4 } from "gsap";

export const getDistance = (pos1: Vector, pos2: Vector): number => {
	const a: number = pos1.x - pos2.x;
	const b: number = pos1.y - pos2.y;
	return Math.sqrt(a * a + b * b);
};

export const setCursorSize = ({ width, height }: CursorSize) => {
	document.documentElement.style.setProperty("--cursorHeight", `${height}px`);
	document.documentElement.style.setProperty("--cursorWidth", `${width}px`);
};

export interface OffsetObject {
	top: number;
	left: number;
	right: number;
	bottom: number;
	height?: number;
	width?: number;
}

interface Rect extends ClientRect {
	x: number;
	y: number;
}
export function getOffset(el: HTMLElement, parent: HTMLElement = document.body): OffsetObject {
	const rect: Rect = el.getBoundingClientRect() as Rect;
	const rectParent: Rect = parent.getBoundingClientRect() as Rect;

	const parentObj: OffsetObject = {
		left: rectParent.left + window.scrollX,
		right: rectParent.right + window.scrollX,
		top: rectParent.top + window.scrollY,
		bottom: rectParent.bottom + window.scrollY,
	};

	return {
		left: rect.left + window.scrollX - parentObj.left,
		right: rect.right + window.scrollX - parentObj.left,
		top: rect.top - parentObj.top + window.scrollY,
		bottom: rect.bottom - parentObj.top + window.scrollY,
		height: rect.height,
		width: rect.width,
	};
}

export function updateRepel(value: number, duration: number = 1) {
	gsap.to(window, duration, { repelForce: value, ease: Power4.easeOut });
}
