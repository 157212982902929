import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html {
	box-sizing: border-box;
	font-family: "Lato", sans-serif;
	font-size: 20px;
	font-weight: 300;
}

*, *:before, *:after {
	box-sizing: inherit;
	// cursor: none;
}

html, body {
	min-height: 100%;
}

body {
	margin: 0;
	background: #fafafa;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
  background-size: 100% 100%;
background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
background-image: radial-gradient(49% 81% at 45% 47%, #FFE20345 0%, #073AFF00 100%),radial-gradient(113% 91% at 20% -2%, #2C16DFFF 1%, #FF000000 99%),radial-gradient(142% 91% at 83% 7%, #6D57DFFF 1%, #FF000000 99%),radial-gradient(142% 91% at -6% 74%, #CE4444FF 1%, #FF000000 99%),radial-gradient(142% 91% at 111% 84%, #7395FFFF 0%, #FF0000FF 100%);
}

canvas {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
`;